// export const tokenomics = [
// 	{
// 		title: "Token Ticker",
// 		value: "FAN",
// 	},
// 	{
// 		title: "Total Token Supply",
// 		value: "1.5B",
// 	},
// 	// {
// 	// 	title: "Private Sale",
// 	// 	value: "20%",
// 	// },
// 	{
// 		title: "Liquidity",
// 		value: "80%",
// 	},
// 	{
// 		title: "Reserve & Marketing",
// 		value: "15%",
// 	},
// 	{
// 		title: "Team",
// 		value: "5%",
// 	},
// ];
export const tokenomics = [
	{
		title: "Tax",
		value: "0%",
	},
	{
		title: "LP Locked & Burned",
		value: "100%",
	},
	// {
	// 	title: "Burn Event",
	// 	value: "40%",
	// },
	{
		title: "Contract Renounced",
		value: "Yes",
	},
];

export const roadmap = [
	{
		title: "Phase 1",
		subheading: "Stealth Launch",
		iconBg: "#010101",
		date: "March 2020 - April 2021",
		points: [
			"Stealth Launch",
			"Build A Strong  Community",
			"On-board Influencers",
			"Get Raiding On Twitter",
			"1000 Holders",
			"1 Mil Mc",
		],
	},
	{
		title: "Phase 2",
		subheading: "More Influencers",
		iconBg: "#010101",
		date: "Jan 2021 - Feb 2022",
		points: [
			"More Influencers",
			"Meme Contests",
			"Apply For Cex Listings",
			"10,000 Holders",
			"10 Mil Mc",
		],
	},
	{
		title: "Phase 3",
		subheading: "Cex Listings",
		iconBg: "#010101",
		date: "Jan 2022 - Jan 2023",
		points: [
			"Cex Listings",
			"Mass Retail Marketing Campaign",
			"Community Rewards",
			"100,000 Holders",
			"100 Mil Mc",
			"Make Everyone Know Who Fanatics are!!",
		],
	},
];
